<template>
  <div class="">
    <ProductModal/>
    <QuoteModal/>

    <div class="sticky-top">
      <nav class="navbar navbar-expand-lg navbar-light bg-light ">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img v-if="user && current_shop" :src="current_shop.logo_url">
            <img v-else src="./assets/images/cm-logo.png">
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link to="/" class="nav-link">Home</router-link>
              </li>
              <li class="nav-item"  v-if="!userIsLogged">
                <router-link to="/login" class="nav-link">Accedi</router-link>
              </li>
             
              <li class="nav-item" v-if="userHasRole('shop_manager')">
                <router-link to="/catalog" class="nav-link">Catalogo</router-link>
              </li>
              


              <li class="nav-item dropdown" v-if="userHasRole('shop_manager') || userHasRole('shop_admin')">
                <a class="nav-link dropdown-toggle" href="#" id="preventiviDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Preventivi
                </a>
                <ul class="dropdown-menu" aria-labelledby="garanzieDropdown">

                  <li >
                    <router-link to="/quotes" class="dropdown-item">Elenco</router-link>
                  </li>

                  <li v-if="userHasRole('shop_manager')">
                    <router-link to="/quote-editor" class="dropdown-item">Crea / Modifica</router-link>
                  </li>

                  
                </ul>
                
              </li>
              
              <li class="nav-item">
                <a v-if="userIsLogged" class="nav-link" href="#" @click.prevent="shopsLogout">Esci</a>
              </li>
            </ul>
            
          </div>
          <div>
            {{ user.email }}
          </div>
        </div>
      </nav>

      <div class="bg-dark p-2">
        <div class="row" v-if="userIsLogged">
          <div class="col-6">
            <div v-if="user && user.external_id" class="d-inline me-4">
              <span class="text-light me-1">Codice cliente</span>
              <span class="badge badge-pill bg-light text-dark" >{{ user.external_id }}</span>
            </div>

            <div v-if="current_shop" class="d-inline me-4">
              <span class="text-light me-1">Negozio</span>
              <span class="badge badge-pill bg-light text-dark" >{{ current_shop.name }}</span>
              <router-link to="/shop-selection" class="d-inline text-light px-1">
                <i class="bi bi-pencil-square"></i>
              </router-link>
              
            </div>

          </div>
          <div class="col-6 text-end">
            
            
            <a v-if="userHasRole('shop_manager')" href="#" class="badge badge-pill bg-primary text-light" data-bs-toggle="modal" data-bs-target="#quoteModal"><i class="bi bi-basket me-2"></i>{{ quote.count }}</a>
          </div>
        </div>
        
        
      </div>
    </div>

    <div class="container-fluid pt-4">
      <router-view/>

      
    </div>

  </div>
</template>

<style lang="scss">
  @import "./styles/shops.scss";
</style>



<script>


import CmShopsMixin from './mixins/CmShopsMixin'
import ProductModal from './components/ProductModal'
import QuoteModal from './components/QuoteModal'

import axios from 'axios'

import _default, { mapState } from 'vuex';



export default{
  components:{
    ProductModal, QuoteModal
  },
  mounted: function(){

    var now = Math.floor(Date.now() / 1000);

    if( this.token_expiration && (this.token_expiration < now))
      this.logout();

    var self = this; 
    axios.get('/config.json')
      .then(
        (response) => {

          if( response.data.api_url != undefined ){
            self.$store.commit('setApiUrl', response.data.api_url);

          }

          if( response.data.request_context != undefined ){
            self.$store.commit('setRequestContext', response.data.request_context);

          }

        }
      ).catch( [(error) => {
        self.$store.commit('setApiUrl', '/connector');
      }]);

      
    
  },
  mixins: [  CmShopsMixin ],
  methods:{
    ping: function(){
      this.api.get('ping').then( function( response ){
        console.log( response.data );
      });
      /*
      .catch(function (error) {
        if (error.response) {
          if( error.response.status == 401)
            self.logout();
        }
      });
      */
    },

 
  },
  computed: {
    ...mapState([
			
			'token_expiration',
			
    ]),
  },
}
</script>

