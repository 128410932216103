import axios from '../../node_modules/axios';
//import { useToast } from "vue-toastification";
import _default, { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
// import { useNotification } from "@kyvg/vue3-notification";
import Toastify from 'toastify-js'
import { bottom, left } from '@popperjs/core';


export default {

	data: () => {
		return {
			api: null,
			toast: null,

			admin_users: [],

			enable_cart_reports: true,
			
		}
	},

    mounted: function(){

		var self = this;


		

		let axios_config = {
			baseURL: this.api_url,
			timeout: 30000,
		};

		moment.updateLocale(moment.locale(), { invalidDate: "-" })

		if( this.token && this.token ){
			axios_config.headers = { 
				'Authorization' : 'Bearer ' + this.token,
				'CM-Request-Context' : this.request_context ,  
			};
		}
		
        this.api = axios.create(axios_config); 

		this.api.interceptors.response.use((response) => response, (error) => {
			// TODO: gestire la response in caso di token scaduto
			if (error.response) {
				if(error.response.status == 401){
					this.logout();
				}
			}
			//this.$store.commit('resetUser');

			throw error;
		});

		// this.notification = useToast();
		this.notification = {
			success: function( message ){
				

				Toastify({
					text: message,
					gravity: 'bottom',
					position: 'left',
					className: "toast-success",
				}).showToast();
			},
			error: function( message ){

				

				Toastify({
					text: message,
					gravity: 'bottom',
					position: 'left',
					className: "toast-danger",
				}).showToast();
			},
		};
		
    },

	methods: {

        userHasRole: function( role ){

			if( !this.user)
				return false;

			return this.user.role == role;

		},

		logout: function(){
			this.$store.commit('resetAll');
			this.$router.push('/login');
		},


		adminGetUsers: function( callback ){
			var self = this;
	
		   this.api.post( 'user/adminGetUsers')
			.then(function (response) {
			  
				if(response.data.users){
				  self.admin_users = response.data.users;
				}

				if( typeof callback === 'function')
              		callback();
			})
	
		},

		
		setCurrentProfile: function( profile ){

			if(this.current_profile && (profile.id == this.current_profile.id))
				return;

			if( this.cart.items.length > 0 && !this.confirm('Modificando il profilo corrente, il carrello verrà svuotato. Per non perderlo procedere col salvataggio.') )
				return;

			this.$store.commit('setCurrentProfile', profile);
			// this.$store.commit('resetCart');
		},

		setCurrentCustomer: function( customer ){

			if(this.current_customer && (customer.id == this.current_customer.id))
				return;

			if( this.cart.items.length > 0 && !this.confirm('Modificando il cliente corrente, il carrello verrà svuotato. Per non perderlo procedere col salvataggio.') )
				return;

			this.$store.commit('setCurrentCustomer', customer);
			
		},


		setCurrentProduct: function( product ){
			
			this.$store.commit('setCurrentProduct', product);
			
		},

		setQuote: function( quote ){
			
			this.$store.commit('setQuote', quote);
			
		},


		addItemToQuote: function(item, type){

			this.$store.commit('addItemToQuote', {
				item: item,
				type: type,
			});

			this.notification.success( 'Prodotto aggiunto correttamente al preventivo' );
		},

		updateQuote: _.debounce( function(){
			this.$store.commit('updateQuote');
		}, 200),

		removeQuoteItem: function( type, item_index ){
			if( this.confirm( 'Rimuovere il prodotto dal preventivo?') ){
				this.$store.commit('removeQuoteItem',  {type: type, item_index: item_index});
				this.notification.success( 'Prodotto rimosso correttamente' );
			}
				
		},


		saveQuote: function( quote, callback ){
            var self = this;

			if( !quote )
				return false;
			

            this.api.post( 'quote/update', { quote: quote, user_id : this.user.id, shop_id : this.current_shop.id } )
                .then(function (response) {
                
                    if( response.data.success ){
						self.notification.success( 'Preventivo salvato correttamente' );
						if(callback)
							callback(response.data.quote);
                    }
                    else{
                        self.notification.error( response.data.message ? response.data.message : 'Errore nel salvataggio del preventivo' );
                    }

                
                })
                .catch(function (error) {
                    self.handleApiError( error );
					if(callback)
						callback(null);
                })
				
        },


		getQuotePdfUrl: function( quote ){
			return this.api_url + '/quote/createPdf/' + quote.id;
		},

		sendQuoteEmail: function( quote_id, customer_email){
			var self = this;
	  
			var post_data = {
			  quote_id: quote_id,
			  customer_email: customer_email,
			  
			}
	  
			this.api.post( 'quote/sendEmail' , post_data)
			.then(function (response) {
				if(response.data.success == 1){
				  
				  self.notification.success( response.data.message ? response.data.message : 'Operazione eseguita correttamente' );
				  
				}
			})
		  },

		/*

		addItemToCart: function( item ){
			this.$store.commit('addItemToCart', item);
		},

		setCartShippingItems: function( items){
			this.$store.commit('setCartShippingItems', items);
		},

		setCartPromotionItem: function( item ){
			this.$store.commit('setCartPromotionItem', item);
		},

		setCartShippingAddress: function( shipping_address_is_new, shipping_address, shipping_address_new ){
			this.$store.commit('setCartShippingAddress', {
									shipping_address_is_new: shipping_address_is_new, 
									shipping_address: shipping_address, 
									shipping_address_new: shipping_address_new
								});
		},

		removeCartItem: function( item_index ){
			if( this.confirm( 'Rimuovere il prodotto dal carrello?' ) )
				this.$store.commit('removeCartItem', item_index);
		},

		updateCart: _.debounce( function(){
			this.$store.commit('updateCart');
		}, 200),

		calculateCartItemTotal( item ){
			var t = item.price * item.quantity;
			if( item.discount_1 )
				t = t * ((100 - item.discount_1) / 100 );
			if( item.discount_2 )
				t = t * ((100 - item.discount_2) / 100 );
			return this.formatPrice(t);
		},
		*/

		formatPrice: function( num ){
			return (Math.round(num * 100) / 100).toFixed(2);
		},

		formatDate: function( dat ){
			return moment( dat ).format('DD/MM/YYYY');
		},

		formatDatetime: function( dat ){
			return moment( dat ).format('DD/MM/YYYY HH:mm');
		},

		confirm: function(message){
			return confirm(message);
		},

		handleApiError( error ){

			console.log( error );

			var message = ' Si è verificato un errore nella richiesta';

			if( error.response && error.response.data && error.response.data.message != undefined)
				message = error.response.data.message;
			else if(error.response && error.response.data && error.response.data.error != undefined)
				message = error.response.data.error;


			this.notification.error( message );
		},

		shopsLogout: function(){
			this.$store.commit( 'setCurrentShop', this.selected_shop );
			this.logout();
		},

		getProductTypeName: function( type ){

			var names = {
				standard: 'Prodotti / Servizi',
				advances: 'Anticipazioni',
			}

			return ( names[type] != undefined ? names[type] : type);

		},


		groupQuoteItemsByType: function( items ){
			var grouped = {};

			for( var i in items ){

				var item = items[i];
				item.original_index = i;

				if( !grouped[item.type] )
					grouped[item.type] = [];

					grouped[item.type].push(item);
			}

			return grouped;
		}

		
	},

	computed: {
		...mapState([
			'api_url',
			'request_context',
			'user',
			'token',
			'token_expiration',
			'configuration',
			

			'current_profile',
			'current_customer',
			'current_product',
			'current_shop',

			'quote',
		]),
		...mapGetters([
			// 'quoteCount'
		]),

		userIsLogged: function( ){
			return  (this.user && this.token != null);
		},


	}
}