<template>

    <div class="modal fade quote-modal" tabindex="-1" id="quoteModal">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header bg-light">
                    <div>
                        <h5 class="modal-title mb-0" v-html="quote.id ? 'Preventivo ' + quote.id : 'Nuovo Preventivo'"></h5>
                        <div class="small" v-if="quote.id" v-html="'Creato il ' + formatDate(quote.created_at)"></div>
                    </div>
                    
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>


                <div class="modal-body">
                    <!--<pre >{{ quote.items }}</pre>-->
                    <div class="customer-info text-end">
                        <strong>{{ quote.last_name }} {{ quote.first_name }}</strong><br>
                        <small v-if="quote.email">{{ quote.email }}</small> <small v-if="quote.phone">Tel. {{ quote.phone }}</small><br>
                        <small v-if="quote.address">{{ quote.address }}</small> <small v-if="quote.zip_code">{{ quote.zip_code }}</small> <small v-if="quote.city">{{ quote.city }}</small> <small v-if="quote.district"> ({{ quote.district }})</small><br>
                        <em><small v-if="quote.corpse_last_name">{{ quote.corpse_last_name }}</small> <small v-if="quote.corpse_first_name">{{ quote.corpse_first_name }}</small></em>

                        <br>
                    </div>
                    <div class="alert alert-warning" v-if="formIncomplete">Per procedere con il salvataggio è necessario compilare l'intestazione del preventivo con <strong>nome, cognome, email o numero di telefono</strong></div>

                    <div v-if="quote.items.length > 0" >
                    <hr>
                        <table class="table table-condensed table-quote mb-0" >



                            <tbody v-if="items_by_type['standard'] && items_by_type['standard'].length > 0">
                                <tr class="bg-light">
                                    <td>&nbsp;</td>
                                    <td colspan="5"><strong class="small">Prodotti / Servizi</strong></td>
                                </tr>

                                <tr v-for="item, idx in items_by_type['standard']" :key="quote.id" :class="item.type == 'standard' ? '' : 'bg-light'">
                                    <td class="col-1 align-top"><CatalogImage :key="item.id" :code="item.external_id" :imgclass="'img-fluid'"/></td>
                                    <td  class="col-5 align-top">
                                        <div class="mb-2">
                                            <input type="text" v-model="item.description" class="form-control">
                                        </div>
                                        <span :title="item.id" class="badge badge-pill bg-dark">{{ item.external_id }}</span> - <small>{{ getProductTypeName(item.type) }}</small>
                                        
                                    </td>
                                    
                                    <td class="col-2 align-top ">
                                        <div class="input-group">
                                            <input type="number" class="form-control text-end" v-model="item.price" :disabled="!quote.is_editable" @change="updateRow(item)">
                                            <span class="input-group-text">&euro;</span>
                                        </div>
                                        <div class="small" v-if="item.price_original" v-show="show_original_prices">Originale: <strong>{{ formatPrice(item.price_original) }} &euro;</strong></div>

                                    </td>
                                    <td class="col-1 align-top">
                                        <input type="number" class="form-control text-end" v-model="item.quantity" :disabled="!quote.is_editable" @change="updateRow(item)">
                                    </td>
                                    <td class="col-2 align-top ">
                                        <div class="input-group">
                                            <input type="number" class="form-control text-end" v-model="item.row_total" readonly>
                                            <span class="input-group-text">&euro;</span>
                                        </div>
                                        <div class="small" v-if="item.row_total_original" v-show="show_original_prices">Originale: <strong>{{ formatPrice(item.row_total_original) }} &euro;</strong></div>
                                        
                                    </td>
                                    <td class="col-2 align-top">
                                        <td><button  @click="removeQuoteItem( type, item.original_index )" class="btn btn-warning"><i class="bi bi-dash-circle"></i></button></td>
                                    </td>

                                </tr>
                                <tr v-if="enable_discount">
                                    <td class="col-1 align-top">&nbsp;</td>
                                    <td  class="col-5 align-top">
                                        Sconto su Prodotti / Servizi
                                    </td>
                                    
                                    <td class="col-2 align-top">
                                    
                                    </td>
                                    <td class="col-1 align-top">
                                    
                                    </td>
                                    <td class="col-2 align-top">
                                        <div class="input-group">
                                            <span class="input-group-text">-</span>
                                            <input type="number" class="form-control form-control-sm text-end" v-model="quote.discount_amount" @change="updateQuote" :disabled="!quote.is_editable">
                                            <span class="input-group-text">&euro;</span>
                                        </div>
                                    </td>
                                    <td class="col-2 align-top">
                                        <td><button  @click="quote.discount_amount = 0; enable_discount = false;" class="btn btn-warning"><i class="bi bi-dash-circle"></i></button></td>
                                    </td>

                                </tr>
                            </tbody>

                            <tbody v-if="items_by_type['advances'] && items_by_type['advances'].length > 0">
                                <tr class="bg-light">
                                    <td>&nbsp;</td>
                                    <td colspan="5"><strong class="small">Anticipazioni</strong></td>
                                </tr>
                                <tr v-for="item, idx in items_by_type['advances']" :key="quote.id" :class="item.type == 'advances' ? '' : 'bg-light'">
                                    <td class="col-1 align-top"><CatalogImage :key="item.id" :code="item.external_id" :imgclass="'img-fluid'"/></td>
                                    <td  class="col-5 align-top">
                                        <div class="mb-2">
                                            <input type="text" v-model="item.description" class="form-control">
                                        </div>
                                        <span :title="item.id" class="badge badge-pill bg-dark">{{ item.external_id }}</span> - <small>{{ getProductTypeName(item.type) }}</small>
                                        
                                    </td>
                                    
                                    <td class="col-2  align-top">
                                        <div class="input-group">
                                            <input type="number" class="form-control text-end" v-model="item.price" :disabled="!quote.is_editable" @change="updateRow(item)">
                                            <span class="input-group-text">&euro;</span>
                                        </div>
                                        <div class="small" v-if="item.original_price" v-show="show_original_prices">Originale: {{ formatPrice(item.original_price) }} &euro;</div>
                                    </td>
                                    <td class="col-1 align-top">
                                        <input type="number" class="form-control text-end" v-model="item.quantity" :disabled="!quote.is_editable" @change="updateRow(item)">
                                    </td>
                                    <td class="col-2  align-top">
                                        <div class="input-group">
                                            <input type="number" class="form-control text-end" v-model="item.row_total" readonly>
                                            <span class="input-group-text">&euro;</span>
                                        </div>
                                        <div class="small" v-if="item.row_total_original" v-show="show_original_prices">Originale: <strong>{{ formatPrice(item.row_total_original) }} &euro;</strong></div>

                                        
                                    </td>
                                    <td class="col-2 align-top">
                                        <td><button  @click="removeQuoteItem( type, item.original_index )" class="btn btn-warning"><i class="bi bi-dash-circle"></i></button></td>
                                    </td>

                                </tr>
                            </tbody>

                            <tbody>

                                
                                <!--
                                <tr>
                                    <td class="col-1">&nbsp;</td>
                                    <td  class="col-5">
                                        <em>Totale Parziale</em>
                                    </td>
                                    
                                    <td class="col-2">
                                    
                                    </td>
                                    <td class="col-1">
                                    
                                    </td>
                                    <td class="col-2 ">
                                        <div class="input-group">
                                            <input type="number" class="form-control form-control-sm text-end" v-model="quote.total" readonly>
                                            <span class="input-group-text">&euro;</span>
                                        </div>
                                    </td>
                                    <td class="col-2">
                                    
                                    </td>

                                </tr>
                            -->
                                
                                <tr class="bg-light">
                                    <td class="col-1 align-top">&nbsp;</td>
                                    <td  class="col-5 align-top">
                                        <strong>TOTALE PREVENTIVO</strong>
                                    </td>
                                    
                                    <td class="col-2 align-top">
                                    
                                    </td>
                                    <td class="col-1 align-top">
                                    
                                    </td>
                                    <td class="col-2 align-top ">
                                        <div class="input-group">
                                            <input type="number" class="form-control form-control-sm text-end" :value="formatPrice(quote.total_final)" readonly>
                                            <span class="input-group-text">&euro;</span>
                                        </div>
                                        <div class="small "  v-show="show_original_prices">
                                            Originale: <strong>{{ formatPrice(quote.total_original) }} &euro;</strong><br>
                                            Differenza: <strong>{{  ((quote.total >= quote.total_original) ? '+' : '-' ) + formatPrice(quote.total - quote.total_original) }} &euro;</strong>
                                        </div>

                                        
                                    </td>
                                    <td class="col-2 align-top">
                                    
                                    </td>

                                </tr>
                            </tbody>



                        </table>
                        
                        
                    

                        <div class="discount-enabler">

                            <small>
                                <a href="#" class="me-4" v-if="!enable_discount" @click.prevent="enable_discount = !enable_discount">Mostra sconto</a>
                                <a href="#" class="me-4" v-if="enable_discount" @click.prevent="enable_discount = !enable_discount">Nascondi sconto</a>

                            </small>

                            <small>
                                <a href="#" v-if="!show_original_prices" @click.prevent="show_original_prices = !show_original_prices">Mostra prezzi originali</a>
                                <a href="#" v-if="show_original_prices" @click.prevent="show_original_prices = !show_original_prices">Nascondi prezzi originali</a>

                            </small>
                        </div>

                    </div>
                    <div v-else class="mt-2 alert alert-info">Nessun prodotto inserito nel preventivo</div>


                    <div class="card card-body quote-notes p-3 my-3">

                        <div class="row">
                            <div class="col-sm-12 pb-4">
                                <div class="form-group">
                                    <label>Nome preventivo</label>
                                    <input class="form-control form-control-sm" v-model="quote.quote_name">
                                </div>
                            </div>
                        </div>

                        
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Note riservate</label>
                                    <textarea class="form-control form-control-sm" rows="3" v-model="quote.company_notes"></textarea>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Note per il cliente (compariranno sul preventivo)</label>
                                    <textarea class="form-control form-control-sm" rows="3" v-model="quote.customer_notes"></textarea>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    
                    <div v-if="quote.id">
                        
                        
                        
                        <div class="row">
                            <div class="col">
                                <small>Preventivo salvato con id <strong>{{ quote.id }}</strong>. Ultima modifica: <strong>{{ formatDatetime(quote.updated_at) }}</strong></small>
                            </div>
                            <div class="col">
                                <a class="btn btn-primary w-100" target="_blank" :href="getQuotePdfUrl(quote)">Scarica PDF</a>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                <input type="text" class="form-control" v-model="customer_email">
                                <button class="btn btn-primary" @click="sendQuoteEmail( quote.id, customer_email )">Invia per email</button>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                    <div v-else class="alert alert-info" else>Per scaricare il PDF o inviare il preventivo via email è prima necessario salvarlo.</div>

                </div>
            
                <div class="modal-footer d-block bg-light">
                <div class="row">
                    <div class="col-sm-6">
                        
                    </div>
                    <div class="col-sm-6 text-end">
                        <button type="button" class="btn btn-warning me-2" @click="resetQuote">Resetta </button>
                        <button type="button"  class="btn btn-success me-2" @click="localSaveQuote" :disabled="is_saving || formIncomplete">Salva </button>
                        
                        <button @click="gotoEditor" data-bs-dismiss="modal" class="btn btn-primary me-2">Crea / Modifica</button>
                        <!--<router-link to="/quote-editor" data-bs-dismiss="modal" class="btn btn-primary me-2">Crea / Modifica</router-link>-->
                        <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">Chiudi</button>
                        
                    </div>
                </div>  
                
            </div>
            </div>
            
            
        </div>
    </div>
    
</template>

<script>
import { defineComponent } from 'vue'
import CmShopsMixin from '../mixins/CmShopsMixin'

import CatalogImage from '../components/CatalogImage'
import ProfileBadge from '../components/ProfileBadge'

export default defineComponent({
    
    name: 'QuoteModal',
    mixins: [ CmShopsMixin ],
    components: { CatalogImage, ProfileBadge },
    data: function(){
        return {
                
            // current_quote: {},
            customer_email: '',
            enable_discount: false,
            is_saving: false,
            show_original_prices: false,

                
        }
    },
    mounted: function(){
        // this.current_quote = this.quote;
        this.customer_email = this.quote.email;

        if( this.quote.discount_amount > 0)
            this.enable_discount = true;
    },
    methods:{
        updateRow: function(item){
            item.row_total = item.price * item.quantity;
            this.updateQuote();
        },

        resetQuote: function(){
            if(confirm('Procedere con il reset del preventivo corrente? Anche i dati relativi al cliente verranno cancellati.'))
                this.$store.commit('resetQuote');
        },
        localSaveQuote: function(){
            var self = this;
            self.is_saving = true;
            this.saveQuote( this.quote, function( quote){
                self.is_saving = false;

                if(quote)
                    self.$store.commit('setQuote', quote);
            });
        },

        gotoEditor: function(){
            this.$router.push('/quote-editor');
        },

        
    },
    computed: {
        items_by_type: function(){
            return this.groupQuoteItemsByType( this.quote.items );
        },
        formIncomplete: function(){
            return (this.quote.first_name == '') || (this.quote.last_name == '') || ((this.quote.email == '') && (this.quote.phone == '')) ;
        }
    },
    watch: {
            
    }
})
</script>