<template>

    <div class="modal fade product-modal" tabindex="-1" id="productModal">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content" v-if="current_product">
                <div class="modal-header">
                    <p class="modal-title">Dettagli Prodotto {{ current_product.external_id }}</p>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <figure class="text-center">
                        <CatalogImage :code="current_product.external_id" :key="current_product.external_id" :imgclass="'img-product img-fluid'"/>
                    </figure>
                    <!--
                    <h3 class="badge bg-dark badge-product mb-3">{{ current_product.external_id  }}</h3>
                    <div class=" mb-5">
                        <div class="mb-2"><strong>{{ current_product.description }}</strong></div>
                        <hr> 
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="small" v-if="current_product.packaging_quantity">Quantità per confezione: <strong>{{ current_product.packaging_quantity }}</strong></div>
                                <div class="small" v-if="current_product.weight">Peso unitario: <strong>{{ current_product.weight }}</strong> kg</div>
                            </div>
                            <div class="col-sm-4">

                                
                                <div class="input-group mb-3" v-if="current_product.current_price">
                                    <span class="input-group-text">&euro;</span>
                                    <input type="text" readonly class="form-control text-end" aria-label="Prezzo" :value="show_price ? formatPrice(current_product.current_price) : '*****'">
                                    <button class="btn btn-secondary" type="button" @click="show_price = !show_price "><i :class="'bi ' + ( show_price ? 'bi-eye-slash' : 'bi-eye' )"></i></button>
                                </div>

                                
                            </div>
                        </div>
                        
                        
                    </div>
                    -->

                    
                    
                    <div class="modal-footer d-block">
                        <div class="row">
                            <div class="col-sm-2">
                                <h3 class="badge bg-dark badge-product d-inline-block">{{ current_product.external_id  }}</h3>
                            </div>
                            <div class="col-sm-7 text-center">
                                
                                <div class="d-inline-block "><strong>{{ current_product.description }}</strong></div>
                                <!--<small class="text-danger" v-if="current_product && current_product.disable_discounts">Su questo prodotto non è possibile applicare sconti</small><br>-->
                                
                            </div>
                            <div class="col-sm-3 text-end">
                                <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">Chiudi</button>
                                <!--
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" 
                                @click="addItemToCart( cart_item )">Aggiungi</button>
                                -->
                            </div>
                        </div>  
                        
                    </div>
                </div>
            
            </div>
        </div>
    </div>
    
</template>

<script>
import { defineComponent } from 'vue'
import CmShopsMixin from '../mixins/CmShopsMixin'

import CatalogImage from '../components/CatalogImage'


export default defineComponent({
    
  name: 'ProductModal',
  mixins: [ CmShopsMixin ],
  components: { CatalogImage },
  data: function(){
      return {
            show_price: false,


            
      }
  },
  
  methods:{
     
  },
  computed: {
      
  },
  watch: {
        

        

      
  }
})
</script>