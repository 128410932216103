import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: {
    api_url: null,
    request_context: null,
    token: null,
    token_expiration: null,

    user : {
      
      role: null,
      name: null,
      email: null,

      profiles: [],
    },
    configuration: {},

    quote:{

      id: null,
      user_id: null,
      shop_id: null,
      status: null,
      is_editable: true,

      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      zip_code: '',
      district: '',
      corpse_last_name: '',
      corpse_first_name: '',
      
      total: 0,
      count: 0,
      discount_amount: 0,
      total_final: 0,

      company_notes: '',
      customer_notes: '',

      items:[],
    },

    current_shop: null,
    current_profile: null,
    current_customer: null,
    current_product: null,
  },
  mutations: {

    setApiUrl: function( state, api_url ){
      state.api_url = api_url;
    },

    setRequestContext: function( state, request_context ){
      state.request_context = request_context;
    },

    handleLoginResponse: function( state, data ){

      if( data.token )
        state.token = data.token;
      
      if( data.token )
        state.token = data.token;

      if( data.user )
        state.user = data.user;

      if( data.configuration )
        state.configuration = data.configuration;

      if( data.token_expiration )
        state.token_expiration = data.token_expiration;

    },

    resetUser: function( state ){
      state.token = null;
      state.user = {};
    },

    
    setQuote: function( state, quote ){
      state.quote = quote;

    },

    resetQuote: function( state ){
      state.quote = {

        id: null,
        user_id: null,
        shop_id: null,
        status: null,
        is_editable: true,

        quote_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        zip_code: '',
        district: '',
        corpse_last_name: '',
        corpse_first_name: '',
        
        items:[],
        total: 0,
        count: 0,
        discount_amount: 0,
        total_final: 0,

        company_notes: '',
        customer_notes: '',
      }

    },
    


    resetAll: function( state ){
      state.token = null;
      state.user = {};
      state.current_profile = null;
      state.current_customer = null;
      state.current_product = null;
      state.current_shop = null;
      state.configuration = {};

      this.commit('resetQuote');
  
    },

    setCurrentProfile: function( state, profile ){
      state.current_profile = profile;
      state.current_customer = null;

      var cart = state.cart;

      state.cart = cart;
    },

    setCurrentCustomer: function( state, customer ){
      state.current_customer = customer;

      var cart = state.cart;

      state.cart = cart;
    },

    setCurrentProduct: function( state, product ){
      
      state.current_product = product;
    },

    setCurrentQuote: function(state, quote){
      state.quote = quote;
    },

    addItemToQuote: function(state, payload){

      if( !payload.item || !payload.type )
        return false;


			var item =   state.quote.items.find(function(it){ return it.id == payload.item.id; });
      
      var is_new = false;
      if( !item ) {

        // DEPRECATED: passo solo i dati che mi servono veramente
        //  item = payload.item.id;

        item = {};
        item.id = null;
        item.type = payload.type;
        item.product_id = payload.item.id;
        item.description = payload.item.description;
        item.external_id = payload.item.external_id;
        item.price = payload.item.current_price;
        item.price_original = payload.item.current_price;
        item.quantity = 0;
        is_new = true;
      }
        
      item.quantity += 1;
      item.notes = '';
      item.has_price = (item.price > 0);
      item.row_total = (item.price > 0) ? (item.price * item.quantity) :0;
      item.row_total_original = item.row_total;

      if(is_new){
        if( item.type == 'standard')
          state.quote.items.unshift( item );
        else
          state.quote.items.push( item );
          
      }
        

      this.commit('updateQuote');
      

    },
    

    updateQuote: function( state ){

      

      var total = 0;
      var total_original = 0;
      var count = 0;

      if( state.quote.items.length > 0){
        for( var i in state.quote.items){

          var item = state.quote.items[i];

            item.row_total = item.quantity* item.price;
            item.row_total_original = item.quantity* item.price_original;

            total += item.row_total;
            total_original += item.row_total_original;
            count += item.quantity;
        }
        
      }


      state.quote.total = total;
      state.quote.total_original = total_original;
      state.quote.total_final = total - state.quote.discount_amount;
      
      state.quote.count = count;
      

    },

    removeQuoteItem: function( state, payload ){

      state.quote.items.splice( payload.item_index, 1);
      this.commit('updateQuote');
    },


    /*
    addItemToCart: function( state, item ){

      if( item.product_id && item.quantity > 0 ){
        // per ora non considero un aggiornamento di riga perche potrebbe avere sconti diversi 
        // var found = state.cart.items.find(element => element.id == item.id);
        state.cart.items.push( item );
                 
      }
      this.commit('updateCart');

    },

    removeCartItem: function( state, cart_index ){
      state.cart.items.splice( cart_index, 1);
      this.commit('updateCart');
    },


    setCartShippingItems: function( state, items ){

      state.cart.shipping_items = items ;
      this.commit('updateCart', true);

    },

    setCartPromotionItem: function( state, item ){

      state.cart.promotion_item = item ;
      this.commit('updateCart', true);

    },

    setCartShippingAddress: function( state, payload ){

      state.cart.shipping_address_is_new = payload.shipping_address_is_new ;
      state.cart.shipping_address = payload.shipping_address ;
      state.cart.shipping_address_new = payload.shipping_address_new ;

    },

    setCartDiscount: function( state, discount ){

      state.cart.discount_percentage = discount ;
      
      this.commit('updateCart', true);

    },

    
    updateCart: function( state, is_cart_addition ){


      if(!is_cart_addition && (state.cart.shipping_items || state.cart.promotion_item) ) {
        alert('Modificando il carrello sarà necessario ricalcolare le spese di spedizione e scegliere nuovamente la promozione.');

        state.cart.shipping_items = null ;
        state.cart.promotion_item = null ;
      }


      state.cart.items = state.cart.items.map( function( item ){
        var t = item.price * item.quantity;
        if( item.discount_1 )
          t = t * ((100 - item.discount_1) / 100 );
        if( item.discount_2 )
          t = t * ((100 - item.discount_2) / 100 );
			  item.row_total = t;
        return item;
      });

      state.cart.count = state.cart.items.length;
      state.cart.total = state.cart.items.reduce((a, b) => +a + +b.row_total, 0);

      if( state.cart.shipping_items ){
        for( var i in state.cart.shipping_items){
          state.cart.total += state.cart.shipping_items[i].row_total;
        }
      }
        
      if( state.cart.discount_percentage ){

        var discount = parseFloat(state.cart.discount_percentage);

        

        state.cart.total_final = state.cart.total * ((100 - discount ) / 100);
        state.cart.discount_amount = state.cart.total - state.cart.total_final;
        
      }
      else{
        state.cart.total_final = state.cart.total;

      }


      state.cart.total_weight = parseFloat(state.cart.items.reduce((a, b) => +a + +(b.weight * b.quantity), 0).toFixed(2));
    },
*/


    setCurrentShop: function( state, shop ){
      state.current_shop = shop;

    },

  },
  getters: {
    /*
    quoteCount: state => {
      var count = 0;
      for( var i in state.quote.items){

        
            count += state.quote.items[i].quantity
        
      }
      return count;
      
    }
    */
  },
 
  plugins: [vuexLocal.plugin]
})
